@import './Variables.scss';

.card-container {
    width: 12rem;
    height: 19rem;
    margin: 0.7rem;
    background-color: transparent;
    perspective: 71rem;

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: 0.5s;
        transform-style: preserve-3d;
        border: 0.07rem solid #ccc;
        border-radius: 1.1rem;
        box-shadow: 0 0.3rem 0.6rem 0 rgba(0,0,0,0.2);
        background-color: white;
        color: red;
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
        padding: 1.1rem;
        opacity: 1;
        transition: 0.5s;
        transition-timing-function: cubic-bezier(0.51, -0.25, 0.33, 1.07);
    }

    &.flip-card .flip-card-inner {
        transform: rotateY(180deg);
        
        .flip-card-front {
            opacity: 0;
        }
    }

    .flip-card-back {
        transform: rotateY(180deg);
        overflow: hidden;

        .flag-logo {
            filter: brightness(50%) sepia(100) saturate(100) hue-rotate(25deg);
            padding: 0;
            margin: auto;
            width: 100%;
            transform: translateY(3.6rem);
        }
    }
    
    .flip-card-front {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card-icon {
            width: 2.6rem;
            height: 2.6rem;
            margin: 0 auto;
        }
    }

    &:hover {
        cursor: pointer;

        .flip-card-inner {
            box-shadow: 0 0.6rem 1.1rem 0 rgba(0,0,0,0.2);
        }
    }

    &.red-flag {
        .flip-card-inner {
            background-color: $base-red;
            color: white;
        }

        .flag-logo {
            filter: none;
        }
    }

    &.selected {
        .flip-card-inner {
            transform: translateY(-1rem);
            box-shadow: 0 0.6rem 1.1rem 0 rgba(256,0,0,1);
        }
    }

    .card-description {
        font-weight: bold;
        font-size: 1.4rem;
        word-break: break-word;
    }
}
