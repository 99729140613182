@import './Variables.scss';

.winner-dialog {
    .MuiPaper-root {
        background-color: white;
        padding: 2.1rem 0;
        margin: 1rem;    
    }

    .MuiDialog-paperFullWidth {
        width: calc(100% - 4rem);
    }

    .MuiDialogTitle-root {
        h2 {
            text-align: center;
            color: black;
            font-size: 2rem;
        }
    }

    .close-btn {
        color: $base-red;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;

        .MuiIcon-root {
            font-size: 2rem;
        }
    }
}