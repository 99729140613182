@import './Variables.scss';

.no-game-container {
  &.MuiContainer-root {
    background: $base-red;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: none;
  }
}

.game-title {
  &.MuiTypography-h3 {
    font-weight: bold;
    font-size: 4rem;
    letter-spacing: -2px;
    word-spacing: 10px;
    text-transform: uppercase;
    line-height: 3rem;
    text-align: center;
  }
}

.game-subtitle {
  &.MuiTypography-h4 {
    margin-bottom: 6rem;
    font-size: 1.5rem;
    word-spacing: 8px;
    text-transform: uppercase;
  }
}

.start-game-btn,
.view-game-btn {
  @include base-btn;
}

#flag-logo {
  width: 12rem;
  padding: 0 1rem 1rem 1rem;
}

.join-game {
  label {
    &.MuiFormLabel-root {
      color: white;
      font-size: 1.5rem;
    }

    &.MuiInputLabel-formControl {
      transform: translate(0, 16px) scale(1);
    }

    &.MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.6);
    }
  }

  &.MuiInput-underline:before {
    border-bottom-color: white;
  }
}