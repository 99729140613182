@import './Variables.scss';

.game-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    transition: background 1s ease;
    color: black;
}

.play-container {
    .player-title {
        margin-bottom: 1rem;
        text-align: center;
    }

    .instructions {
        margin-bottom: 0.7rem;    
    }

    .perk-container {
        margin-bottom: 1rem;
    }

    .select-perks-btn,
    .select-flags-btn {
        @include base-btn-alt;
        width: 14.3rem;
        margin-top: 2.85rem;
    }

    .player-status {
        margin-bottom: 0.85rem;        

        .ready-check {
            &.MuiIcon-root {
                margin-right: 0.85rem;
                font-size: 3rem;
                color: $base-red;
            }
        }
        
        .progress-status {
            &.MuiCircularProgress-colorPrimary {
                margin-right: 0.85rem;
                color: $base-red;
            }
        }
    }

    @media screen and (orientation:landscape) {
        .cards-container {
            flex-direction: row;

            .MuiGrid-container {
                width: auto;
                margin-bottom: 0;
            }
        }
     }
}

.guide-container {
    text-align: center;
    height: 90%;
    margin-right: 0.85rem;

    .guide-title {
        font-weight: bold;
        letter-spacing: 0.15rem;
        word-spacing: 0.4rem;
        text-transform: uppercase;
    }

    img {
        height: 2.3rem;
        margin-right: .6rem;
        margin-bottom: 0.2rem;
        transform: translateY(0.5rem);
    }
}

.loading-screen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $base-red;

    .MuiCircularProgress-colorPrimary {
        color: white;
    }
}

.game-not-started {
    background: $base-red;
    color: white;
}

.add-bot-btn,
.start-game-btn,
.join-game-btn,
.skip-guide-btn,
.update-settings-btn {
    @include base-btn;
}

.update-settings-btn {
    &.MuiButton-root {
        margin-top: 2.9rem;
        zoom: 75%;
    }
}

.next-round-btn {
    @include base-btn-alt;

    &.MuiButton-root {
        margin-top: 3.6rem;
    }
}

.die {
    margin: 0.35rem;

    label {
        border: 0.07rem solid;
        width: 4.7rem;
        height: 4.7rem;
        border-radius: 0.7rem;

        .MuiButtonBase-root {
            padding-top: 0;
        }
    }
}

.section {
    h3 {
        text-align: center;
        margin: 2.1rem 0 0.6rem 0;
    }
}

.settings-title {
    &.MuiTypography-root {
        margin-bottom: 0.6rem;
    }
}

.points-input {
    &.MuiInputBase-root {
        font-size: 1.5rem;
        margin-left: 0.4rem;
        width: 6rem;

        input {
            text-align: center;
            height: 1rem;
        }
    }
}

.game-over-container {
    .section {
        z-index: 2;
        color: white;
    }

    .winner-section {
        margin-top: 3.6rem;
        color: white;
        z-index: 2;
    }

    .fireworks {
        img {
            display: none;
        }

        #fireworksField {
            z-index: 1;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .fullscreen-btn.MuiButtonBase-root {
        color: white;
        z-index: 2;
    }

    + .fullscreen-btn.MuiButtonBase-root {
        color: white;
    }
}
