$base-red: #ce0e0e;

@mixin base-btn {
    &.MuiButton-root {
        background-color: white;
        color: $base-red;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: -.1rem;
        width: 15rem;

        &:disabled {
            background-color: #ccc;
            opacity: 0.6;
            color: $base-red;
        }
        
        .MuiCircularProgress-root {
            width: 2.85rem !important;
            height: 2.85rem !important;
        }
    }
}

@mixin base-btn-alt {
    &.MuiButton-root {
        color: white;
        background-color: $base-red;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: -.1rem;
        width: 15rem;

        &:disabled {
            background-color: $base-red;
            opacity: 0.3;
        }
        
        .MuiCircularProgress-root {
            width: 2.85rem !important;
            height: 2.85rem !important;
        }
    }

    &.MuiButton-contained:hover {
        &.Mui-disabled {
            background-color: $base-red;
        }
    }
}